<template>
  <div>

    <div class="col-md-12 section_bg_gray">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="ptb-50">
              <h2 class="text-left">Qui sommes nous ?</h2>
            </div>
            <div class="row">
              <div class="col-md-6">
                <img src="@/assets/infographie.png" class="img-fluid">
              </div>
              <div class="col-md-6 about-us">
                <p>
                  <strong>FreeBoss</strong> est une <strong>application de gestion de comptabilité en ligne</strong> et d’accompagnement permettant aux entreprise, start-up, PME de pouvoir optimiser la gestion de leur comptabilité. Filiale digitale de la <strong>Compagnie Sinequanone</strong>, FreeBoss accompagne plus d'une dizaine de chefs d’entreprise.
                </p>
                <p>
                  Basé à Paris, il intervient <strong>partout en France</strong> auprès de <strong>TPE</strong>, <strong>consultants</strong>, <strong>PME</strong>, <strong>professionnels</strong>. <strong>Solutions 100% ergonomique et fiable avec des fonctionnalités a l'appuie</strong>, nous nous adaptons aux exigeances de la clientèle.
                </p>
                <p>
                  Organisé par fils de fonctionnalité, FreeBoss vous accompagne en matière de <strong>comptabilité</strong>, <strong>paie</strong>, <strong>facturation</strong> et <strong>gestion des transactions</strong>. Notre objectif ? l'automatisation des opérations comptable.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="ptb-50">
              <h2 class="text-left">Notre fonctionnement</h2>
            </div>
            <div class="row">
              <div class="col-md-12 about-us">
                <p>
                  Nous éffectuerons pour vous <strong>l’ensemble de vos obligations comptables </strong>: bilan, compte de résultat, évaluation des dépenses, etc. Et pour permettre une très bonne collaboration, nous mettons à votre disposition <strong>un espace de visualisation de vos activités</strong>.
                  Grâce à nous, terminé la saisie fastidieuse et l'empillage des documents ! Chaque fils du temps, vos données ou informations comptables sont importés automatiquement et de manière sécurisée depuis notre interface. Il ne vous reste plus qu’à <strong>regroupés vos opérations en catégories</strong> pour :
                </p>
                <ul>
                  <li>
                    Vous permettre de bénéficier <strong>d’indicateurs de performance en temps réel</strong> : chiffre d’affaires, trésorerie, recettes et dépenses, etc. Une gestion au jour le jour pour gérer efficacement votre activité.
                  </li>
                  <li>
                    Nous permettre de réaliser vos <strong>comptes annuels et déclarations fiscales</strong>, ainsi que toutes les missions que vous désirez nous confier.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'About-us',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .about-us{
    text-align: justify;
    font-size: 14pt;
  }
  .about-us ul{
    list-style: none;
  }
  .about-us ul li::before{
    color: red;
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    color: red;
  }

  .ptb-50{
    padding: 50px 50px 20px 50px;
  }

  .ptb-50 h2{
    color: #dc393a;
    font-weight: bold;
  }

  .section_bg_gray {
    background-color: bold;
    padding-bottom: 50px;
  }

</style>
